<template>
  <div class="grid no-gutter flex-column">
    <div class="col">
      <CompanyInformation></CompanyInformation>
    </div>
    <!-- <div class="col" v-if="!checkRole(['sales'])">
      <UserClients @editUser="viewEditUser" :showAddUser="false" :clients="true"></UserClients>
    </div> -->
    <div class="col">
      <ApplicationsTable></ApplicationsTable>
    </div>
    <div class="col">
      <CasesTable></CasesTable>
    </div>
    <div
      class="col"
      v-if="
        checkRole([
          'certification-manager',
          'quality-assurance-manager',
          'product-manager',
          'admin',
          'manager-for-certification-decision',
        ])
      "
    >
      <!-- <Card>
        <template #title>
          {{ $t("certifications.title") }}
        </template>

        <template #content>
         
        </template>
      </Card> -->
       <CertificationsTable></CertificationsTable>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { FilterMatchMode, FilterOperator } from "primevue/api";
import CompanyInformation from "../CompanyInformation.vue";
import CasesTable from "../Cases/CasesTable.vue";
import ApplicationsTable from "../Applications/ApplicationsTable.vue";
import CertificationsTable from "../Certifications/CertificationsTable.vue";

export default {
  name: "Clients",
  components: {
    CompanyInformation,
    CasesTable,
    ApplicationsTable,
    CertificationsTable,
  },
  data() {
    return {
      filters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        companyName: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
      },
      loading: true,
      userId: null,
      addEditUserVisible: false,
    };
  },
  computed: {
    ...mapGetters(["applications", "checkRole"]),
  },
  async mounted() {
    // this.dataUserId = this.$route.query.userId;
    // await this.$store.dispatch("applications");
    // this.loading = false;
    this.$action("client-information-page-view");
  },
  methods: {
    viewEditUser(id) {
      this.userId = id;
      this.addEditUserVisible = true;
    },
    closeEditUser() {
      this.userId = null;
      this.addEditUserVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.actionButton {
  padding: 1%;
}

.actionButtonImage {
  width: 2rem;
}

.p-progressbar {
  border-radius: 100px;
}

.p-card {
  margin-bottom: 2%;
}
</style>
